<template>
  <div class="lead-status" :class="statusClass">
    {{ status }}
    <slot></slot>
  </div>
</template>

<script>
import { leadStatus } from "@/enums";

export default {
  components: {},
  mixins: [],
  props: {
    status: {
      type: String
    }
  },
  data: function () {
    return {};
  },
  computed: {
    statusClass() {
      switch (this.status) {
        case leadStatus.PENDING:
          return "bg-warning fg-white";
        case leadStatus.ACCEPTED:
          return "bg-blue-800 fg-white";
        case leadStatus.CLOSED:
          return "bg-success fg-white";
        case leadStatus.DROPPED:
          return "bg-grey-400 fg-grey-900";

        default:
          return "";
      }
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.lead-status {
  text-align: center;
  padding: 8px 12px;
  border-radius: 50em;
}
</style>
